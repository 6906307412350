import React from "react";
import Layout from "../Components/Layout";
import MainTitle from "../common/MainTitle/MainTitle";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import styled from "styled-components";

const Center = styled.div`
  text-align: center;
  width: 100%;
`;
const links = ["アクセス", "スクールバスルート"];

const BusPage = props => {
  return (
    <>
      <Layout
        links={links}
        title="School bus"
        subTitle="スクールバス"
        seoTitle="届出方法について"
        seoDescrption="スタッフ募集中！狭山スイミングプールで一緒に働きませんか。"
        seoKeywords="求人,スタッフ募集,所沢,狭山市,スポーツ,運動,狭山市バイト,高い時給"
      >
        <Container>
          <p>
            日頃より当プールをご利用頂き誠にありがとうございます。この度沢山のご要望がありました、新狭山駅方面にスクールバスが運行できる事となりました。
          </p>
          <Center>
            <Image thumbnail fluid src="https://images.prismic.io/sayama-swimming-club/15947274-4a46-4a88-9264-befbcd605eb2_%E3%82%B9%E3%82%AF%E3%83%BC%E3%83%AB%E3%83%8F%E3%82%99%E3%82%B9%E7%94%BB%E5%83%8F.jpg?auto=compress,format" />
          </Center>
          <MainTitle title="狭山市・新狭山方面" />
          <Center>
            <Image thumbnail fluid src="https://images.prismic.io/sayama-swimming-club/27ca5a03-04e2-4baf-8271-8409f0f062e8_school-bus-sayama-1.png" />
          </Center>
          <MainTitle title="入曽・東急狭山団地・フラワーヒル方面" />
          <Center>
            <Image thumbnail fluid src="https://images.prismic.io/sayama-swimming-club/9b0699e4-6bbe-44fc-8812-093ab7825167_school-bus-sayama-2.png" />
          </Center>
        </Container>
      </Layout>
    </>
  );
};

export default BusPage;
